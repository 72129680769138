import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        leftItems: [
            { title: 'Panic Alarm', description: 'Hold 3 secs to activate SOS, security guards will be alerted', imageSrc: 'assets/img/icares/features/alarm.svg' },
            { title: 'Feedback / Report', description: 'Easy & Simple | Private & Confidential | Easy to follow up | Responsive', imageSrc: 'assets/img/icares/features/feedback.svg' },
            { title: 'Bill Payment', description: 'No More Missing Invoices | Pay Anytime | Payment History | Receipts', imageSrc: 'assets/img/icares/features/billpayment.svg' },
        ],
        rightItems: [
            { title: 'Digital Key', description: 'Unlock access door / facility room with your mobile phone', imageSrc: 'assets/img/icares/features/digitalkey.svg' },
            { title: 'My Face', description: 'Unlock the barrier gate / access door / lift with your face', imageSrc: 'assets/img/icares/features/myface.svg' },
            { title: 'My Vehicles', description: 'Automated Car Plate Recognition', imageSrc: 'assets/img/icares/features/myvehicles.svg' },
        ],
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <div id="features" className="feature-section ptb-100 ">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-9">
                        <div className="section-heading text-center mb-5">
                            <h2>App Features</h2>
                            <p>A Mobile App Paired With A Powerful Yet User Friendly Backend System.</p>
                        </div>
                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="col-lg-4 col-md-12">
                        <div className="row">
                            {this.state.leftItems.map((i) => {
                                return  <div className="col-12">
                                    <div className="d-flex align-items-start mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src={ i.imageSrc } alt={ i.title } style={{ border: '2px solid #3E14E5', borderRadius: '10px', width: '22%' }} className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2" style={{ fontWeight: '900' }}>{ i.title }</h5>
                                            <p>{ i.description }</p>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 d-none d-sm-none d-md-block d-lg-block">
                        <div className="position-relative pb-md-5 py-lg-0">
                            <img alt="placeholder" src="assets/img/icares/home/app-view.png" className="img-center img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className="row">
                            {this.state.rightItems.map((i) => {
                                return  <div className="col-12">
                                    <div className="d-flex align-items-start  mb-sm-0 mb-md-5 mb-lg-5">
                                        <img src={ i.imageSrc } alt={ i.title } style={{ border: '2px solid #3E14E5', borderRadius: '10px', width: '22%' }} className="img-fluid mr-3" />
                                        <div className="icon-text">
                                            <h5 className="mb-2" style={{ fontWeight: '900' }}>{ i.title }</h5>
                                            <p>{ i.description }</p>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Feature;
